import { Dimen } from "./Dimen";
import { Font } from "./Font";
import { LocalizedText } from "./LocalizedText";

export interface Content {
  type: Content.Type;
  size?: Content.Size;
  cornerRadius: Dimen.CornerRadius;
  source?: any;
  style: {};
  styleBase: {};
  styleCustom: {};
  stylePrivate: {};
  center: boolean;
  
  addStyle: (style: {}) => Content
  addCenter: (center: boolean) => Content
}
export namespace Content {

  export enum Type {
    image,
    video,
    text
  }

  export class Size {
    width: Dimen.Value;
    height: Dimen.Value;

    constructor(
      width: number,
      height: number
    ) {
      this.width = new Dimen.Value(width);
      this.height = new Dimen.Value(height);
    }

    static withValues(width: number, height: number) {
      return new Content.Size(width, height);
    }
  }

  export class Image implements Content {
    type: Type;
    size?: Size;
    cornerRadius: Dimen.CornerRadius;
    source: any;
    style: {};
    styleBase: {};
    styleCustom: {};
    stylePrivate: {};
    center: boolean;

    addStyle = (style: {}) => {
      return new Image(
        this.cornerRadius,
        this.source,
        style,
        this.center,
        this.size
      )
    }

    addCenter = (center: boolean) => {
      return new Image(
        this.cornerRadius,
        this.source,
        this.styleCustom,
        center,
        this.size
      )
    }

    constructor(
      cornerRadius: Dimen.CornerRadius,
      source: any,
      styleCustom: {},
      center: boolean,
      size?: Content.Size
    ) {
      this.type = Type.image;
      this.size = size;
      this.cornerRadius = cornerRadius;
      this.source = source;
      this.center = center;
      this.styleCustom = styleCustom;
      var baseStyle = {
        borderRadius: cornerRadius.value
      }
      if (size != null) {
        baseStyle = {
          ...baseStyle,
          ...{
            width: size.width.value,
            height: size.height.value
          }
        }
        if (size.width < size.height) {
          baseStyle = {
            ...baseStyle,
            ...{
              width: size.width.value,
              height: "auto"
            }
          }
        }
        if (size.height < size.width) {
          baseStyle = {
            ...baseStyle,
            ...{
              width: "auto",
              height: size.height.value
            }
          }
        }
        if (size.width === size.height) {
          baseStyle = {
            ...baseStyle,
            ...{
              width: size.width.value,
              height: size.height.value
            }
          }
        }
      }
      this.styleBase = baseStyle;
      this.stylePrivate = center ? {
        display: "flex",
        justifyContent: "center"
      } : { 
        display: "block"
      };
      this.style = {
        ...this.styleCustom,
        ...this.stylePrivate,
        ...this.styleBase
      };
    }
  }

  export class Text implements Content {
    type: Type;
    cornerRadius: Dimen.CornerRadius;
    style: {};
    styleBase: {};
    styleCustom: {};
    stylePrivate: {};
    font: Font;
    localizedText: LocalizedText;
    center: boolean;

    addStyle = (style: {}) => {
      return new Text(
        this.cornerRadius,
        style,
        this.font,
        this.localizedText,
        this.center
      )
    }

    addCenter = (center: boolean) => {
      return new Text(
        this.cornerRadius,
        this.styleCustom,
        this.font,
        this.localizedText,
        center
      )
    }

    constructor(
      cornerRadius: Dimen.CornerRadius,
      styleCustom: {},
      font: Font,
      localizedText: LocalizedText,
      center: boolean
    ) {
      this.type = Type.text;
      this.cornerRadius = cornerRadius;
      this.styleCustom = styleCustom;
      this.styleBase = {
        ...font.getCSS(),
        borderRadius: cornerRadius,
        display: "block"
      };
      this.stylePrivate = center ? { textAlign: "center" } : {};
      this.style = {
        ...this.styleCustom,
        ...this.stylePrivate,
        ...this.styleBase
      };
      this.font = font;
      this.localizedText = localizedText;
      this.center = center;
    }
  }

  export class Video implements Content {
    type: Type;
    cornerRadius: Dimen.CornerRadius;
    source: any;
    style: {};
    styleBase: {};
    styleCustom: {};
    stylePrivate: {};
    styleContainer: {};
    center: boolean;
    maxWidth?: number;
    maxHeight?: number;

    addStyle = (style: {}) => {
      return new Video(
        this.cornerRadius,
        this.source,
        style,
        this.center,
        this.maxWidth,
        this.maxHeight
      )
    }

    addCenter = (center: boolean) => {
      return new Video(
        this.cornerRadius,
        this.source,
        this.styleCustom,
        center,
        this.maxWidth,
        this.maxHeight
      )
    }

    constructor(
      cornerRadius: Dimen.CornerRadius,
      source: any,
      styleCustom: {},
      center: boolean,
      maxWidth?: number,
      maxHeight?: number
    ) {
      this.type = Type.video;
      this.cornerRadius = cornerRadius;
      this.source = source;
      this.styleCustom = styleCustom;
      this.styleContainer = {
        borderRadius: cornerRadius.value,
        overflow: "hidden"
      };
      var styleBase = {};
      if (maxWidth != null) {
        styleBase = { 
          ...styleBase,
          ...{ maxWidth: maxWidth }
        }
      }
      if (maxHeight != null) {
        styleBase = { 
          ...styleBase,
          ...{ maxHeight: maxHeight }
        }
      }
      this.styleBase = styleBase;
      this.stylePrivate = center ? {
        position: "relative",
        left: "50%",
        transform: "translate(-50%)"
      } : {};
      this.style = {
        ...this.styleCustom,
        ...this.stylePrivate,
        ...this.styleBase
      };
      this.center = center;
    }
  }
}
import { CSSProperties } from "react";
import { Color } from "./Color";
import { Dimen } from "./Dimen";

export class Font {
  color: Color;
  family: Font.Family;
  size: Dimen.Size;
  weight: Font.Weight;

  constructor(
    color: Color,
    family: Font.Family,
    size: Dimen.Size,
    weight: Font.Weight
  ) {
    this.color = color;
    this.family = family;
    this.size = size;
    this.weight = weight;
  }

  getCSS = () => {
    return { 
      color: this.color.value,
      fontFamily: this.family.valueOf(),
      fontSize: Font.FontSize.ofSize(this.size).value,
      fontWeight: this.weight.valueOf()
    } as CSSProperties;
  }
}

export namespace Font {
  
  export enum Family {
    OpenSans = 'Open Sans',
    OpenSansCondensed = 'Open Sans Condensed',
    Oswald = 'Oswald'
  }

  export class FontSize implements Dimen.Value {
    value: number
    pixels: string

    constructor(value: number) {
      this.value = value;
      this.pixels = value.toString() + "px";
    }
  }
  export namespace FontSize {
    export const ofSize = (size: Dimen.Size) => {
      switch (size) {
        case Dimen.Size._01: return new Font.FontSize(12);
        case Dimen.Size._02: return new Font.FontSize(16);
        case Dimen.Size._03: return new Font.FontSize(20);
        case Dimen.Size._04: return new Font.FontSize(24);
        case Dimen.Size._05: return new Font.FontSize(32);
        case Dimen.Size._06: return new Font.FontSize(40);
      }
    }
  }

  export enum Weight {
    bold = "bold",
    normal = "normal"
  }

}
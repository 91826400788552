export namespace Dimen {
  
  export enum Size { _01, _02, _03, _04, _05, _06 }
  
  export interface Value {
    value: number
    pixels: string
  }
  export class Value implements Dimen.Value {
    value: number
    pixels: string
    
    constructor(value: number) {
      this.value = value;
      this.pixels = value.toString() + "px";
    }
  }

  export class CornerRadius implements Dimen.Value {
    value: number
    pixels: string

    constructor(value: number) {
      this.value = value;
      this.pixels = value.toString() + "px";
    }
  }
  export namespace CornerRadius {
    export const zero: Dimen.CornerRadius = new Dimen.CornerRadius(0);

    export const ofSize = (size: Dimen.Size) => {
      switch (size) {
        case Dimen.Size._01: return new Dimen.CornerRadius(4);
        case Dimen.Size._02: return new Dimen.CornerRadius(8);
        case Dimen.Size._03: return new Dimen.CornerRadius(12);
        case Dimen.Size._04: return new Dimen.CornerRadius(16);
        case Dimen.Size._05: return new Dimen.CornerRadius(24);
        case Dimen.Size._06: return new Dimen.CornerRadius(32);
      }
    }

    export const inside = (cornerRadius: Dimen.CornerRadius, spacer: Dimen.Spacer) => {
      return cornerRadius.value.valueOf() - spacer.value.valueOf()
    }
  }

  export class Spacer implements Dimen.Value {
    value: number
    pixels: string

    constructor(value: number) {
      this.value = value;
      this.pixels = value.toString() + "px";
    }

    negative = () => {
      return new Dimen.Spacer(-this.value);
    }
  }
  export namespace Spacer {
    export const zero = new Dimen.Spacer(0);
    export const ofSize = (size: Dimen.Size) => {
      switch (size) {
        case Dimen.Size._01: return new Dimen.Spacer(2);
        case Dimen.Size._02: return new Dimen.Spacer(4);
        case Dimen.Size._03: return new Dimen.Spacer(8);
        case Dimen.Size._04: return new Dimen.Spacer(16);
        case Dimen.Size._05: return new Dimen.Spacer(32);
        case Dimen.Size._06: return new Dimen.Spacer(64);
      }
    }
  }

  export namespace Margin {
    export const content = Dimen.Spacer.ofSize(Dimen.Size._04);
    export const contentSectionTitle = Dimen.Spacer.ofSize(Dimen.Size._06);
    export const contentSectionSubtitle = Dimen.Spacer.ofSize(Dimen.Size._04);

    export const all = (spacer: Dimen.Spacer) => {
      return { 
        marginTop: spacer.value,
        marginBottom: spacer.value,
        marginLeft: spacer.value,
        marginRight: spacer.value
      };
    }
    export const top = (spacer: Dimen.Spacer) => { 
      return { marginTop: spacer.value };
    }
    export const bottom = (spacer: Dimen.Spacer) => { 
      return { marginBottom: spacer.value };
    }
    export const left = (spacer: Dimen.Spacer) => { 
      return { marginLeft: spacer.value };
    }
    export const right = (spacer: Dimen.Spacer) => { 
      return { marginRight: spacer.value };
    }
    export const vertical = (spacer: Dimen.Spacer) => {
      return { 
        marginTop: spacer.value,
        marginBottom: spacer.value
      };
    }
    export const horizontal = (spacer: Dimen.Spacer) => {
      return { 
        marginLeft: spacer.value,
        marginRight: spacer.value
      };
    }
  }

  export namespace Padding {
    export const content = Dimen.Spacer.ofSize(Dimen.Size._04);
    export const contentSectionTitle = Dimen.Spacer.ofSize(Dimen.Size._06);
    export const contentSectionSubtitle = Dimen.Spacer.ofSize(Dimen.Size._04);

    export const all = (spacer: Dimen.Spacer) => {
      return { 
        paddingTop: spacer.value,
        paddingBottom: spacer.value,
        paddingLeft: spacer.value,
        paddingRight: spacer.value
      };
    }
    export const top = (spacer: Dimen.Spacer) => { 
      return { paddingTop: spacer.value };
    }
    export const bottom = (spacer: Dimen.Spacer) => { 
      return { paddingBottom: spacer.value };
    }
    export const left = (spacer: Dimen.Spacer) => { 
      return { paddingLeft: spacer.value };
    }
    export const right = (spacer: Dimen.Spacer) => { 
      return { paddingRight: spacer.value };
    }
    export const vertical = (spacer: Dimen.Spacer) => {
      return { 
        paddingTop: spacer.value,
        paddingBottom: spacer.value
      };
    }
    export const horizontal = (spacer: Dimen.Spacer) => {
      return { 
        paddingLeft: spacer.value,
        paddingRight: spacer.value
      };
    }
  }
}
export interface Color {
    value: string;
    setAlpha: (alpha: number) => Color
}

export class ColorRGBA implements Color {
    red: number;
    green: number;
    blue: number;
    alpha: number;
    value: string;

    constructor(
        red: number,
        green: number,
        blue: number,
        alpha: number
    ) {
        this.red = red;
        this.green = green;
        this.blue = blue;
        this.alpha = alpha;
        this.value = `rgba(${this.red.toString()},${this.green.toString()},${this.blue.toString()},${this.alpha.toString()})`;
    }

    setAlpha = (alpha: number) => {
        return new ColorRGBA(this.red, this.green, this.blue, alpha)
    }
}

export namespace Color {
    export const black: Color = new ColorRGBA(0, 0, 0, 1);
    export const grayDark: Color = new ColorRGBA(64, 64, 64, 1);
    export const gray: Color = new ColorRGBA(128, 128, 128, 1);
    export const grayLight: Color = new ColorRGBA(192, 192, 192, 1);
    export const white: Color = new ColorRGBA(255, 255, 255, 1);
    export const clear: Color = new ColorRGBA(0, 0, 0, 1);
}
import { Component } from 'react';
import { Dimen } from './model/Dimen';
import { State } from './model/State';
import { ViewModel } from './model/ViewModel';

export type AppProps = {};
export type AppState = State.Value;

class App extends Component<AppProps, AppState> {
  state: AppState = new State.Value();
  
  componentDidMount = () => {
    this.setState(new State.Value(
        State.ContentMode.forWindowWidth(window.innerWidth),
        State.HeaderMode.normal,
        State.Locale.forLocale(global.navigator.language)
      )
    )
    window.addEventListener(ViewModel.Event.resize, this.onResize);
    window.addEventListener(ViewModel.Event.scroll, this.onScroll);
  }

  onResize = () => {
    const newState = this.state.updateForWindowWidth(window.innerWidth);
    this.updateStateIfNeeded(newState);
  }

  onScroll = () => {
    const newState = this.state;
    this.updateStateIfNeeded(newState);
  }

  updateStateIfNeeded(state: State.Value) {
    if (state !== this.state) {
      this.setState(state);
    }
  }

  render = () => {
    const styleTitle = ViewModel.Fonts.title.getCSS();
    const styleSubtitle = ViewModel.Fonts.subtitle.getCSS();
    const styleButton = ViewModel.Fonts.button.getCSS();
    const styleCopyright = ViewModel.Fonts.copyright.getCSS();
    const styleLocation = ViewModel.Fonts.location.getCSS();
    return <div style={Dimen.Margin.all(Dimen.Margin.contentSectionSubtitle)}>
        <div style={Dimen.Margin.top(Dimen.Margin.contentSectionTitle)}/>
        <div style={styleTitle}>jaredbourgeois.com</div>

        <div style={Dimen.Margin.top(Dimen.Margin.contentSectionTitle)}/>
        <div style={styleSubtitle}>Welcome to my home of shameless self-promotion.</div>

        <div style={Dimen.Margin.top(Dimen.Margin.contentSectionTitle)}>
          <ul style={styleSubtitle}>
            • <a style={styleButton} target="_blank" href="https://github.com/jaredmbourgeois" rel="noreferrer">GitHub</a>
          </ul>
          <ul style={styleSubtitle}>
            • Resume - <a style={styleButton} target="_blank" href="jared_bourgeois_resume.txt" rel="noreferrer">txt</a> | <a style={styleButton} target="_blank" href="jared_bourgeois_resume.docx" rel="noreferrer">doc</a> | <a style={styleButton} target="_blank" href="jared_bourgeois_resume.pdf" rel="noreferrer">pdf</a>
          </ul>
          <ul style={styleSubtitle}>
            • <a style={styleButton} target="_blank" href="https://letsgostreaking.app" rel="noreferrer">Streak</a> on the <a target="_blank" style={styleButton} href="https://apps.apple.com/us/app/streak/id1070975673" rel="noreferrer">App Store</a>
          </ul>
        </div>                

        <div style={Dimen.Margin.top(Dimen.Margin.contentSectionTitle)}/>
        <div style={styleSubtitle}>Take care,</div>

        <div style={Dimen.Margin.top(Dimen.Margin.contentSectionSubtitle)}/>
        <div style={styleSubtitle}>
          <a style={styleButton} target="_blank" href="mailto:mail@jaredbourgeois.com" rel="noreferrer">Jared</a>
        </div>

        <div style={Dimen.Margin.top(Dimen.Margin.contentSectionTitle)}/>
        <div style={styleCopyright}>© 2024 Jared Bourgeois</div>

        <div style={Dimen.Margin.top(Dimen.Spacer.ofSize(Dimen.Size._01))}/>
        <div style={styleLocation}>Baton Rouge, Louisiana</div>
      </div>
  }
}

export default App;

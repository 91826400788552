import { Content } from "./Content";
import { Dimen } from "./Dimen";
import { Font } from "./Font";
import { Color } from "./Color";
import { State } from "./State";
import { LocalizedText } from "./LocalizedText";
// import ContentImport from "./ContentImport";

export namespace ViewModel {
  export enum Event {
    resize = "resize",
    scroll = "scroll"
  }
  export enum Identifier {
    home = "home"
  }

  export namespace ImageSource {
    // export const jaredAndKeighley = ContentImport.image.jaredAndKeighley;
  }

  export namespace Dimens {
    export const cornerRadiusImagesAndVideos = Dimen.CornerRadius.ofSize(Dimen.Size._04);
  }
  
  export namespace Fonts {
    export const title: Font = new Font(
      Color.grayLight,
      Font.Family.OpenSans,
      Dimen.Size._04,
      Font.Weight.bold
    );
    export const subtitle: Font = new Font(
      Color.gray,
      Font.Family.OpenSans,
      Dimen.Size._03,
      Font.Weight.bold
    );
    export const copyright: Font = new Font(
      Color.gray,
      Font.Family.OpenSans,
      Dimen.Size._01, // 12
      Font.Weight.bold
    ); 
    export const location: Font = new Font(
      Color.gray,
      Font.Family.OpenSans,
      Dimen.Size._01, // 12
      Font.Weight.normal
    ); 
    export const button: Font = new Font(
      Color.gray,
      Font.Family.OpenSans,
      Dimen.Size._03, // 16
      Font.Weight.bold
    );
  }

  export const contentTitleForMode = (contentMode: State.ContentMode, text: LocalizedText) => {
    return new Content.Text(
      Dimen.CornerRadius.zero,
      {},
      ViewModel.Fonts.title,
      text,
      contentMode === State.ContentMode.mobile
    );
  }
  export const contentSubtitleForMode = (contentMode: State.ContentMode, text: LocalizedText) => {
    return new Content.Text(
      Dimen.CornerRadius.zero,
      {},
      ViewModel.Fonts.subtitle,
      text,
      contentMode === State.ContentMode.mobile
    );
  }

}